
import { Component, Mixins } from 'vue-property-decorator';
import {
  Office,
  MapSettings,
  MapMarker,
  Profile,
} from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { Map } from 'client-website-ts-library/components';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';

import { ProfileFilter } from 'client-website-ts-library/filters';

import ProfileCards from '@/components/ProfileCards.vue';

@Component({
  components: {
    Map,
    Form,
    ProfileCards,
  },
})
export default class ContactUs extends Mixins(View) {
  private office: Office | null = null;

  private mapSettings: MapSettings | null = null;

  private profiles: Profile[] | null = null;

  mounted() {
    API.Profiles.Search(new ProfileFilter()).then((profiles) => { this.profiles = profiles; });

    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;

      this.mapSettings = new MapSettings({
        GreedyZoom: true,
        Interactive: true,
        Markers: [
          new MapMarker({
            Address: `${this.office.AddressLine1} ${this.office.AddressLine2}`,
            Title: `<a target='_blank' href='https://maps.google.com/?q='${office.AddressLine1} ${office.AddressLine2}>${office.AddressLine1} ${office.AddressLine2}</a>`,
          }),
          new MapMarker({
            Address: '420 Pitt Street, Sydney NSW 2000',
            Title: "<a target='_blank' href='https://maps.google.com/?q=420 Pitt Street, Sydney NSW 2000'>420 Pitt Street, Sydney NSW 2000</a>",
          }),
        ],
      });
    });
  }
}
